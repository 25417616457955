<template>
  <el-dialog
    title="站内信"
    :visible.sync="messageDetailId.status"
    width="70vw"
    top="11vh"
  >
    <el-main class="main">
      <!-- 标题 -->
      <h1 class="main-title">{{ messageDetailId.title }}</h1>
      <!-- 内容 -->
      <div class="main-content">
        <!-- 临时效果展示，后续改用富文本插件 -->
        <p>为更好的增加用户体验，新增xxxxx功能</p>
        <h1>1、XXXXX功能</h1>
        <img src="@/assets/new.png" />
        <p>
          XXXXX功能的描述三维可视化、动态数据可视化、仿真数据可视化
          、分析结果可视化、
          虚拟现实可视化、轻量化大数据可视化等功能。XXXXX功能的描述三维可视化、动态数据可视化、仿真数据可视化
          、分析结果可视化、 虚拟现实可视化、轻量化大数据可视化等功能。
        </p>
        <div>
          <h2>longmap官方</h2>
          <h2>2121.05.17 15:00</h2>
        </div>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
export default {
  name: 'Message',
  data() {
    return {};
  },
  computed: {
    messageDetailId: {
      get() {
        return this.$store.state.userData.messageDetailId;
      },
      set(val) {
        this.$store.commit('userData/setMessageDetailId', val);
      }
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
// 内容
.main {
  // 标题
  &-title {
    margin: 20px 0 30px;
    text-align: center;
    height: 23px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  // 内容
  &-content {
    width: 100%;
    height: 87%;
    border-radius: 6px;
    overflow: hidden;
    // 临时效果待删
    p {
      margin-top: 16px;
      font-size: 18px;
    }
    h1 {
      margin: 28px 0 20px;
      font-size: 18px;
      font-weight: bold;
    }
    img {
      width: 478px;
      border-radius: 8px;
    }
    div {
      width: 300px;
      margin: 50px 0 0 1000px;
    }
    h2 {
      text-align: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }
}
</style>
